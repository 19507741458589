<template>
  <a-alert v-if="isShowAlert"
           class="mb-20 py-8 px-16 rounded-sm"
           style="border: 1px solid #ffe58f;"
           banner>
    <template #message>
      您的EduSoho销客助手账号将于 {{ $f.datetime(expiryTime) }} 到期，请联系专属客服购买或续费。
      <span class="ml-12 text-admin-primary cursor-pointer"
            @click="customerServiceModalVisible = true">
        联系客服
      </span>
    </template>
  </a-alert>

  <a-modal v-model:visible="customerServiceModalVisible"
           title="联系客服"
           width="400px"
           :footer="null">
    <div class="flex flex-col justify-center items-center my-24">
      <p class="mb-12 text-color-333 text-center font-medium">专属客服二维码</p>
      <a-image :src="require('@/assets/customer_service.png')"
               width="200px"
               height="200px"></a-image>
    </div>
  </a-modal>

  <div class='home-page'>
    <div class="statistics__container">
      <div class="statistics">
        <a-row :gutter="16">
          <a-col class="gutter-row"
                 :span="7">
            <div class="gutter-box">
              <div class="title-mark mt-12 mb-24 ml-16">客户数据</div>
              <section class="statistic-box">
                <a-statistic title="客户总数">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.customerTotalCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>

                <a-statistic title="今日新增客户数">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.customerTodayAddCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>

                <a-statistic title="今日流失">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.customerTodayLostCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>
              </section>
            </div>
          </a-col>
          <a-col class="gutter-row"
                 :span="7">
            <div class="gutter-box">
              <div class="title-mark mt-12 mb-24 ml-16">群数据</div>
              <section class="statistic-box">
                <a-statistic title="群总数">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.groupTotalCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>

                <a-statistic title="今日入群">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.groupTodayAddUserCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>

                <a-statistic title="今日退群">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.groupTodayLostUserCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>
              </section>
            </div>
          </a-col>
          <a-col class="gutter-row"
                 :span="10">
            <div class="gutter-box">
              <div class="title-mark mt-12 mb-24 ml-16">订单数据</div>
              <section class="statistic-box">
                <a-statistic title="累计订单总额">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.orderTotalAmt) /100"
                              :duration="1000"
                              :decimals="2"></count-to>
                  </template>
                </a-statistic>

                <a-statistic title="累计订单总数">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.orderTotalCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>

                <a-statistic title="今日新增订单金额">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.orderTodayAddAmt) /100"
                              :duration="1000"
                              :decimals="2">
                    </count-to>
                  </template>
                </a-statistic>

                <a-statistic title="今日订单数">
                  <template #formatter>
                    <count-to :endVal="Number(statistics.orderTodayAddCnt)"
                              :duration="1000"></count-to>
                  </template>
                </a-statistic>
              </section>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <a-form layout="inline"
            :model="searchList">
      <a-form-item>
        <a-dropdown :trigger="['click']">
          <span class="ant-dropdown-link"
                @click.prevent>
            {{echartsData.status}}
            <CaretDownOutlined :style="{color: '#bebebe', marginLeft: '8px'}" />
          </span>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click="changeType('customer')">新增客户数</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="changeType('group')">新增进群人数</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="changeType('orderAmt')">新增订单金额</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="changeType('orderCnt')">新增订单数</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-form-item>
    </a-form>

    <div id="echarts"
         style="height: 55vh;"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/grid";

import _ from "lodash";
import dayjs from "dayjs";
import { CountTo } from "vue-count-to2";
import { ref, reactive } from "vue";
import { Statistic, Alert, Popover, Image } from "ant-design-vue";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import StatisticsApi from "@/service/api/statistics";
import corporationApi from "@/service/api/corporation";

export default {
  name: "DashboardOverview",
  components: {
    AStatistic: Statistic,
    [Alert.name]: Alert,
    [Popover.name]: Popover,
    [Image.name]: Image,

    CaretDownOutlined,
    CountTo,
  },
  setup() {
    const searchList = reactive({
      type: "customer",
      days: 30,
    });
    const echartsData = reactive({
      xAxisData: [],
      status: "",
      seriesData: [],
    });

    const expiryTime = ref(0);
    const isShowAlert = ref(false);
    const customerServiceModalVisible = ref(false);

    const getExpiryTime = async () => {
      // TODO: 获取到期时间
      const { endDate } = await corporationApi.get();
      expiryTime.value = endDate;

      isShowAlert.value = judgeIsLt30Days();
    };

    const judgeIsLt30Days = () => {
      const now = dayjs();

      const expireTimeSubtract30Days = dayjs(expiryTime.value).subtract(
        30,
        "day"
      );

      return expireTimeSubtract30Days.isBefore(now);
    };

    getExpiryTime();

    return {
      searchList,
      echartsData,

      expiryTime,
      isShowAlert,
      customerServiceModalVisible,
    };
  },
  data() {
    return {
      statistics: {
        //今日新增客户数
        customerTodayAddCnt: 0,
        //今日流失客户数
        customerTodayLostCnt: 0,
        //客户总数
        customerTotalCnt: 0,
        //今日入群
        groupTodayAddUserCnt: 0,
        //今日退群
        groupTodayLostUserCnt: 0,
        //群总数
        groupTotalCnt: 0,
        //今日新增订单金额
        orderTodayAddAmt: 0,
        //今日新增订单数
        orderTodayAddCnt: 0,
        //订单总金额
        orderTotalAmt: 0,
        //订单总数
        orderTotalCnt: 0,
      },
      dateList: [],
      chart: {},
    };
  },
  mounted() {
    this.getStatisticsValue();
    this.changeType("customer");
    const that = this;
    try {
      window.onresize = function temp() {
        that.chart.resize();
      };
    } catch (error) {
      console.log(error);
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      this.chart.dispose();
    } catch (err) {
      next();
      return;
    }
    next();
  },
  methods: {
    drawLine() {
      let option = {};
      let indexChart = echarts.init(document.getElementById("echarts"));

      // 绘制图表
      option = {
        color: ["#31A1FF"],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "4%",
          right: "4%",
          width: "87%", // 宽度
          height: "79%", // 高度
        },
        xAxis: {
          splitNumber: 30,
          boundaryGap: false,
          data: this.echartsData.xAxisData,
        },
        yAxis: {},
        series: [
          {
            name: this.echartsData.status,
            type: "line",
            data: this.echartsData.seriesData,
            showSymbol: false,
            symbolSize: 4, //折线点的大小
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(49,161,255,0.25)" },
                { offset: 1, color: "#fff" },
              ]),
            }, //填充区域样式
            lineStyle: {
              width: 1.5,
            },
          },
        ],
      };
      indexChart.setOption(option);
      this.chart = indexChart;
    },
    async getStatisticsValue() {
      this.statistics = await StatisticsApi.getHomePageStatsValue();
    },
    getLastDays() {
      let date1 = new Date();
      let dateList = new Map();
      for (let i = 29; i >= 0; i--) {
        let date2 = new Date(date1);
        let key = this.$f
          .datetime(date2.setDate(date1.getDate() - i))
          .substring(0, 10);
        let value = 0;
        dateList.set(key, value);
      }
      this.dateList = dateList;
    },
    async getStatisticsData(type) {
      this.getLastDays();
      const data = await StatisticsApi.getHomePageStatsRange(this.searchList);
      _.forOwn(data, (value, key) => {
        if (type === "orderAmt") {
          this.dateList.set(key, Number(value) / 100);
        } else {
          this.dateList.set(key, Number(value));
        }
      });

      this.echartsData.xAxisData = [...this.dateList.keys()];
      this.echartsData.seriesData = [...this.dateList.values()];
    },
    async changeType(type) {
      this.searchList.type = type;
      let newList = new Map([
        ["customer", "新增客户数"],
        ["group", "新增进群人数"],
        ["orderAmt", "新增订单金额"],
        ["orderCnt", "新增订单数"],
      ]);
      this.echartsData.status = newList.get(type);

      await this.getStatisticsData(this.searchList.type);
      this.drawLine();
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.ant-alert-icon) {
  margin-right: 12px;
}
</style>
